import { Box, Divider, Drawer, ListItemButton, ListItemIcon, ListItemText, Toolbar, List } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PBPTLogo from "../../../assets/images/PoweredPT.png";
import { Link as Scroll } from "react-scroll";
import "../../../assets/styles/containers/_sidemenu.scss";
import ISNLogo from "../../../assets/images/ISN.png";
import { PoweredBy } from "../../../assets/svg/Svg";
// import ImageIcon from "@mui/icons-material/Image";
import { useLocation } from "react-router-dom";
// import { useEffect } from "react";

const drawerWidth = 220;
const PDFSideMenu = ({ dataLoaded, pdfData, isNarrative, defaultCheckFlag, categoriesData, isPDFTemplate, setDefaultCheckFlag, imageData, selected, setSelected, isISN, showLoader }) => {
  const location = useLocation();
  const pathName = location.pathname;
  //   useEffect(() => {
  //     if (!defaultCheckFlag) {
  //       if (pdfData.length === 0 && imageData.length > 0) {
  //         setSelected("images");
  //         setDefaultCheckFlag(true);
  //       } else if (pdfData.length > 0 && imageData.length === 0) {
  //         setSelected(pdfData[0].id);
  //         setDefaultCheckFlag(true);
  //       } else if (pdfData.length > 0 && imageData.length > 0) {
  //         setSelected(pdfData[0].id);
  //         setDefaultCheckFlag(true);
  //       } else if (pdfData.length === 0 && imageData.length === 0) {
  //         setSelected(null);
  //       }
  //     }
  //     // eslint-disable-next-line
  //   }, [pdfData, imageData]);

  return (
    <Drawer className="sidemenu_drawer" variant="permanent" sx={{ width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" }, display: { xs: "none", md: "block" } }}>
      {pathName !== "/repair-list" && (
        <>
          <Toolbar />
          <Toolbar />
        </>
      )}
      <Box sx={{ overflow: "auto", paddingTop: 6, height: "100%", color: isNarrative && "#fff" }} className={pathName !== "/repair-list" ? isNarrative ? "narrative__sidemenu__wrapper" : "sidemenu__wrapper" : "sidemenu__wrapper full__height"}>
        <>
          {isPDFTemplate !== 0 ? (
            <List>
              <Scroll className="scroller__menu">
                {/* <Typography style={{ marginTop: "3px" }}>pdf</Typography> */}
                {/* <Divider className="sidemenu_divider"  style={{ marginBottom: "3px" }} /> */}
                {/* {pdfData?.map((value) => {
            return ( */}
                <ListItemButton className={selected === "default" ? "seleted_menu" : "seleted_menu_hover"} onClick={() => setSelected("default")}>
                  <ListItemIcon>
                    <ContentPasteIcon sx={{ color: isNarrative && "#FFF" }} />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{
                    style: {
                      fontFamily: 'Open Sans',
                    }
                  }} primary={"Home Inspection Report"} />
                </ListItemButton>
                {/* );
          })} */}
              </Scroll>
            </List>
          ) : (
            ""
          )}
        </>
        {/* {pdfData.length !== 0 ? (
          <>
            <Typography style={{ marginTop: "3px" }}>pdf</Typography>
            <Divider style={{ marginBottom: "3px" }} /> */}
        {categoriesData
          ?.filter((item) => {
            // Check if the key exists in the object
            if (item.hasOwnProperty("is_pdf_template")) {
              // Filter based on a condition (if key exists)
              return item["is_pdf_template"] === 1; // Replace with your condition
            } else {
              // If the key doesn't exist, don't filter (include the item)
              return true;
            }
          })
          ?.map((value) => {
            return (
              <ListItemButton className={selected === value.pdf_report ? "seleted_menu" : "seleted_menu_hover"} onClick={() => setSelected(value.pdf_report)}>
                <ListItemIcon>
                  <ContentPasteIcon sx={{ color: isNarrative && "#FFF" }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{
                  style: {
                    fontFamily: 'Open Sans',
                  }
                }} primary={value.name.split(".", 1)} />
              </ListItemButton>
            );
          })}
        {/* </>
        ) : (
          ""
        )} */}
        {/* {imageData.length !== 0 ? (
          <>
            <Typography style={{ marginTop: "3px" }}>media</Typography>
            <Divider style={{ marginBottom: "3px" }} />
            <ListItemButton onClick={() => setSelected("images")}>
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText primary={"Images"} />
            </ListItemButton>
          </>
        ) : (
          ""
        )} */}
      </Box>
      {!showLoader && !isNarrative && dataLoaded ? (
        isISN ? (
          <div style={{ backgroundColor: "#f5f6f8", height: "10vh" }}>
            <div style={{ position: "absolute", bottom: 0 }}>
              <div style={{ backgroundColor: "#f5f6f8", height: "100%" }}>
                <div className="powered_by_ISN">
                  <PoweredBy />
                </div>
                <div>
                  <img className="isn_png" onClick={() => window.open("http://inspectionsupport.com/", "_blank")} src={ISNLogo} alt="ISN_logo" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ backgroundColor: "#f5f6f8", height: "10vh" }}>
            <div style={{ backgroundColor: "#f5f6f8", height: "100%", position: "relative" }}>
              <img style={{ position: "absolute", bottom: 0 }} onClick={() => window.open("http://palmtech.com/", "_blank")} src={PBPTLogo} alt="PT_logo" className="powered_by_PT" />
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </Drawer>
  );
};

export default PDFSideMenu;
