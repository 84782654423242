import PDFView from "./PDFView";
import { useEffect } from "react";
import PDFSideMenu from "./PDFSideMenu";
import { useLocation } from "react-router-dom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Grid, CssBaseline, Box } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import AnimatedDots from "../../common/Animated/AnimatedDots";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const Pdf = ({ dataLoaded, pdfURL, isNarrative, isISN, categoriesData, inspectionStatus, isPDFTemplate, selectedPDF, setSelectedPDF }) => {
  const location = useLocation();
  const userData = location.state?.name;
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
  });

  useEffect(() => {
    if (isPDFTemplate === 0 && categoriesData?.length > 0 && !userData) {
      setSelectedPDF(categoriesData[0]?.pdf_report);
    } else {
      if (userData) {
        setSelectedPDF(userData);
      }
    }
    // eslint-disable-next-line
  }, [userData, isPDFTemplate]);

  return (
    <>
      <Box sx={{ display: "flex", overflowX: "hidden" }}>
        <CssBaseline />
        <PDFSideMenu dataLoaded={dataLoaded} isNarrative={isNarrative} isPDFTemplate={isPDFTemplate} isISN={isISN} categoriesData={categoriesData} selected={selectedPDF} setSelected={setSelectedPDF} />
        {window.scroll(0, 0)}
        <Grid container sx={{ pt: { xs: "200px", md: "150px", lg: "150px" } }}>
          <Grid item xs={12} sm={12} md={11} lg={11} sx={{ height: "100vh", pt: "16px", pl: { xs: "5px", md: "'32px'", lg: "32px" }, width: "100%" }}>
            {selectedPDF === "default" ? (
              <>
                {pdfURL ? (
                  <>
                    {inspectionStatus !== "Published" ? (
                      <PDFView fileUrl={pdfURL} inspectionStatus={inspectionStatus} />
                    ) : (
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                        <Viewer defaultScale={1} theme="dark" fileUrl={pdfURL} plugins={[defaultLayoutPluginInstance]} />
                      </Worker>
                    )}
                  </>
                ) : (
                  <div style={{ fontSize: "14px" }}>
                    <p className="loading_text">
                      The PDF version of this inspection report is getting generated
                      <AnimatedDots />
                    </p>
                  </div>
                )}
              </>
            ) : (
              <>
                {selectedPDF ? (
                  <>
                    {inspectionStatus !== "Published" ? (
                      <PDFView fileUrl={selectedPDF} inspectionStatus={inspectionStatus} />
                    ) : (
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                        <Viewer defaultScale={1} theme="dark" fileUrl={selectedPDF} plugins={[defaultLayoutPluginInstance]} />
                      </Worker>
                    )}
                  </>
                ) : (
                  "No PDF Found."
                )}
              </>
            )}
            <br />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Pdf;