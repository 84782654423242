import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import "../../../assets/styles/containers/_fullReport.scss";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Paper, Typography, Collapse, Divider } from "@material-ui/core";

const ServicesLayout = (props) => {
  const { total, orderDiscount, orderCharges, serviceDetails, paymentDetails, companySettings } = props;
  const [expanded, setExpanded] = useState(true);
  const Recipts = [];
  const AmountPaid = [];
  const AmountDiscount = [];
  const handleExpanded = () => setExpanded(!expanded);

  paymentDetails?.forEach((e) => {
    Recipts.push(e.receipt_url);
    if (e.is_paid === 1) AmountPaid.push(e.amount_paid);
  });

  orderDiscount?.forEach((e) => {
    AmountDiscount.push(e.discount);
  });
  // const OpenUrl = () => {
  //   if (Recipts.length > 0) {
  //     Recipts?.forEach((e) => {
  //       if (e) window.open(e, "_blank");
  //     });
  //   }
  // };
  // Initialize a variable to store the sum
  // let sum = 0;
  // useEffect(() => {
  //   // Loop through the array and add up the values
  //   for (let i = 0; i < orderCharges?.length; i++) {
  //     sum += orderCharges[i]?.service?.amount;
  //     console.log(sum)
  //   }
  // }, [orderCharges]);

  // Use the reduce method to find the sum
  const ServiceSum = serviceDetails?.reduce((accumulator, currentObject) => {
    return accumulator + currentObject?.service?.amount;
  }, 0);
  const ChargeSum = orderCharges?.reduce((accumulator, currentObject) => {
    return accumulator + currentObject?.price;
  }, 0);
  const DiscountSum = orderDiscount?.reduce((accumulator, currentObject) => {
    return accumulator + currentObject?.discount;
  }, 0);
  const AmountPaidSum = paymentDetails?.reduce((accumulator, currentItem) => {
    if (currentItem?.is_paid === 1) {
      return accumulator + currentItem.amount_paid;
    }
    return accumulator;
  }, 0);

  let TotalAmount = ServiceSum + ChargeSum - DiscountSum;
  let Due = TotalAmount - AmountPaidSum;
  // let TotalAmountPaid = AmountPaid?.reduce((previousVal, currentVal) => previousVal + currentVal, 0);
  // let TotalDiscount = AmountDiscount?.reduce((previousVal, currentVal) => previousVal + currentVal, 0);
  // console.log(ServiceSum, ChargeSum, DiscountSum, AmountPaidSum, TotalAmount, Due);
  return (
    <Paper className="additional-info-paper" sx={{ mt: "21px" }}>
      <Grid container>
        <Grid item xs={11} onClick={handleExpanded}>
          <Typography className="details-heading">SERVICES</Typography>
        </Grid>
        <Grid item xs={1}>
          {expanded ? <ArrowDropUpIcon className="details-dropdownicon" onClick={handleExpanded} /> : <ArrowDropDownIcon className="details-dropdownicon" onClick={handleExpanded} />}
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expanded}>
            <br />
            {serviceDetails?.map((value, index) => {
              return (
                <Grid container key={index} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={6} className="details-phone-title">
                    {value.service.name}
                  </Grid>
                  <Grid item xs={6} className="details-phone-value" sx={{ color: "#666666", opacity: "80%" }}>
                    ${value.service.amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Grid>
                </Grid>
              );
            })}
            {orderCharges?.length > 0 ? (
              <Grid item xs={11} onClick={handleExpanded}>
                <Typography className="details-heading">CHARGES</Typography>
              </Grid>
            ) : (
              ""
            )}
            {orderCharges?.map((value, index) => {
              return (
                <Grid container key={index} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={6} className="details-phone-title">
                    {value.description}
                  </Grid>
                  <Grid item xs={6} className="details-phone-value" sx={{ color: "#666666", opacity: "80%" }}>
                    ${value.price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Grid>
                </Grid>
              );
            })}
            {orderDiscount?.length > 0 ? (
              <Grid item xs={11} onClick={handleExpanded}>
                <Typography className="details-heading">DISCOUNTS</Typography>
              </Grid>
            ) : (
              ""
            )}
            {orderDiscount?.map((value, index) => {
              return (
                <Grid container key={index} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={6} className="details-phone-title">
                    {value.description}
                  </Grid>
                  <Grid item xs={6} className="details-phone-value" sx={{ color: "#666666", opacity: "80%" }}>
                    - ${value.discount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Divider className="inspection-details-divider" />
            </Grid>
            <Grid container sx={{ mt: 2, mb: 2 }}>
              <Grid item xs={6} className="details-phone-title">
                Total
              </Grid>
              <Grid item xs={6} className="details-phone-value" sx={{ color: "#666666", fontWeight: "700 !important", fontFamily:"Open sans !important" }}>
                ${TotalAmount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Grid>
            </Grid>
            {companySettings?.is_payment_enable === 2 ? (
              <>
                <Grid container sx={{ mt: 4 }}>
                  <Grid item xs={6} className="details-phone-title">
                    Paid
                  </Grid>
                  <Grid item xs={6} className="details-phone-value" sx={{ color: "#666666", opacity: "80%" }}>
                    ${AmountPaidSum?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider className="inspection-details-divider" />
                </Grid>
                <Grid container>
                  <Grid item xs={6} className="details-phone-title">
                    Due
                  </Grid>
                  <Grid item xs={6} className="details-phone-value" sx={{ color: "#666666" }}>
                    ${Due?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
            {/* <Grid item xs={12}>
              <Button onClick={OpenUrl} className="details-viewinvoice" variant="none" endIcon={<ArrowForwardIosIcon />}>
                View invoice
              </Button>
            </Grid> */}
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default ServicesLayout;
