//file imports
import { useEffect } from "react";
import { motion } from "framer-motion";
import ReportContent from "./ReportContent";
import ScrollToTop from "react-scroll-to-top";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../components/Menu/SideMenu";
import "../../../assets/styles/containers/_fullReport.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InspectionDetailsLayout from "./InspectionDetailsLayout";
import { Box, CssBaseline, Grid, Toolbar } from "@mui/material";
import PDFTemplateContent from "./PDFTemplateContent";
import WebWorker from "react-webworker";
import NarrativeInspDetailsLayout from "./narrativeFullReport/NarrativeInspDetailsLayout";
import NarrativeReportContent from "./narrativeFullReport/NarrativeReportContent";

//render function
const FullReport = (props) => {
  const navigate = useNavigate();
  const { reportData, dataLoaded, logo, isISN, isPDFTemplate, isTexasReport, showPDF, showLoader, companySettings, isNarrative } = props;
  useEffect(() => {
    if (isTexasReport && !showPDF) {
      navigate("/pdf");
    }
    // eslint-disable-next-line
  }, []);
  // return
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <WebWorker url={"../worker.js"} workerOptions={{ workerPath: "https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js" }}>
          <CssBaseline />
          <SideMenu dataLoaded={dataLoaded} isNarrative={isNarrative} categoriesData={reportData?.inspectiontemplates} showLoader={showLoader} isISN={isISN} />
          <Box component="main" sx={{ flexGrow: 1, p: 0 }} ref={props.componentRef}>
            <Toolbar />
            <Toolbar />
            <motion.div initial={{ opacity: 0.5 }} animate={{ opacity: 1 }} style={{ height: "100vh" }}>
              <Grid item xs={12} className="container">
                {isNarrative ? <NarrativeInspDetailsLayout isPDFTemplate={isPDFTemplate} companySettings={companySettings} isISN={isISN} logo={logo} inspectionDetailsData={reportData?.inspection_details} additionalInfo={reportData?.addtional_information} /> : <InspectionDetailsLayout isPDFTemplate={isPDFTemplate} companySettings={companySettings} isISN={isISN} logo={logo} inspectionDetailsData={reportData?.inspection_details} additionalInfo={reportData?.addtional_information} />}
              </Grid>
              <Grid item xs={12} className="container">
                {isNarrative ? <NarrativeReportContent categoriesData={reportData?.inspectiontemplates} summaryData={reportData?.summaryDataNarrativeTemplate} /> : <ReportContent isNarrative={isNarrative} categoriesData={reportData?.inspectiontemplates} />}
                <PDFTemplateContent categoriesData={reportData?.inspectiontemplates} />
                <br />
              </Grid>
            </motion.div>
          </Box>
        </WebWorker>
      </Box>
      <ScrollToTop smooth className="scroll__to__top" component={<ArrowUpwardIcon />} />
    </>
  );
};

export default FullReport;
