import { Box, Divider, Drawer, ListItemButton, ListItemIcon, ListItem, ListItemText, Toolbar, Typography } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PBPTLogo from "../../../assets/images/PoweredPT.png";
import "../../../assets/styles/containers/_sidemenu.scss";
import ISNLogo from "../../../assets/images/ISN.png";
import { PoweredBy } from "../../../assets/svg/Svg";
import ImageIcon from "@mui/icons-material/Image";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const drawerWidth = 220;
const AttachmentSideMenu = ({ dataLoaded, pdfData, defaultCheckFlag, setDefaultCheckFlag, imageData, selected, setSelected, isISN, showLoader, isNarrative }) => {
  const location = useLocation();
  const pathName = location.pathname;
  useEffect(() => {
    if (!defaultCheckFlag) {
      if (pdfData.length === 0 && imageData.length > 0) {
        setSelected("images");
        setDefaultCheckFlag(true);
      } else if (pdfData.length > 0 && imageData.length === 0) {
        setSelected(pdfData[0].id);
        setDefaultCheckFlag(true);
      } else if (pdfData.length > 0 && imageData.length > 0) {
        setSelected(pdfData[0].id);
        setDefaultCheckFlag(true);
      } else if (pdfData.length === 0 && imageData.length === 0) {
        setSelected(null);
      }
    }
    // eslint-disable-next-line
  }, [pdfData, imageData]);

  return (
    <Drawer className="sidemenu_drawer" variant="permanent" sx={{ width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" }, display: { xs: "none", md: "block" } }}>
      {pathName !== "/repair-list" && (
        <>
          <Toolbar />
          <Toolbar />
        </>
      )}
      <Box sx={{ overflow: "auto", paddingTop: 6, height: "100%" }} className={pathName !== "/repair-list" ? isNarrative ? "narrative__sidemenu__wrapper" : "sidemenu__wrapper" : "sidemenu__wrapper full__height"}>
        {pdfData.length !== 0 ? (
          <>
            <Typography style={{ marginTop: "3px" }}>pdf</Typography>
            <Divider style={{ marginBottom: "3px" }} className={isNarrative ? "narratives_sidemenu_divider" : "sidemenu_divider"} />
            {pdfData?.map((value) => {
              return (
                <ListItem disablePadding sx={{ pr: 1 }} >
                  <ListItemButton onClick={() => setSelected(value.id)}>
                    <ListItemIcon>
                      <ContentPasteIcon />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{
                      style: {
                        fontFamily: 'Open Sans',
                      }
                    }} primary={value.name.split(".", 1)} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </>
        ) : (
          ""
        )}
        {imageData.length !== 0 ? (
          <>
            <Typography style={{ marginTop: "3px" }}>media</Typography>
            <Divider style={{ marginBottom: "3px" }} className={isNarrative ? "narratives_sidemenu_divider" : "sidemenu_divider"} />
            <ListItem disablePadding sx={{ pr: 1 }} >
              <ListItemButton onClick={() => setSelected("images")}>
                <ListItemIcon>
                  <ImageIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{
                  style: {
                    fontFamily: 'Open Sans',
                  }
                }} primary={"Images"} />
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          ""
        )}
      </Box>
      {!showLoader && !isNarrative && dataLoaded ? (
        isISN ? (
          <div style={{ backgroundColor: "#f5f6f8", height: "10vh" }}>
            <div style={{ position: "absolute", bottom: 0 }}>
              <div style={{ backgroundColor: "#f5f6f8", height: "100%" }}>
                <div className="powered_by_ISN">
                  <PoweredBy />
                </div>
                <div>
                  <img className="isn_png" onClick={() => window.open("http://inspectionsupport.com/", "_blank")} src={ISNLogo} alt="ISN_logo" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ backgroundColor: "#f5f6f8", height: "10vh" }}>
            <div style={{ backgroundColor: "#f5f6f8", height: "100%", position: "relative" }}>
              <img style={{ position: "absolute", bottom: 0 }} onClick={() => window.open("http://palmtech.com/", "_blank")} src={PBPTLogo} alt="PT_logo" className="powered_by_PT" />
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </Drawer>
  );
};

export default AttachmentSideMenu;
