import { useState, useEffect } from "react";
import { Box, Button, CssBaseline, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import SideMenu from "../../components/Menu/SideMenu";
import ReportContent from "../fullReport/ReportContent";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "../../../assets/styles/containers/_buildRequest.scss";
import ScrollToTop from "react-scroll-to-top";
import CloseIcon from "@mui/icons-material/Close";
import FlagIcon from "@mui/icons-material/Flag";
import Switch from "@mui/material/Switch";
import RepairTotalCostCard from "../../common/collapse/RepairTotalCostCard";
import { useNavigate } from "react-router-dom";
import FilterMobileSection from "./FilterMobileSection";
import { checkTotalItemNPrice, findRatingCount, getCommentReqListId } from "../../helpers/HelperFunctions";
import CreateRequestListModal from "../../common/modal/CreateRequestListModal";
import { apiCall } from "../../utils/action";
import toast from "react-hot-toast";
import Preloader from "../../helpers/Preloader";

const RequestList = ({ dataLoaded, isISN, setSampleComentReqListId }) => {
  const [createReqList, setCreateReqList] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [subject, setSubject] = useState("");
  const [filteredRating, setFilteredRating] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const navigate = useNavigate();

  const { selectedCount } = checkTotalItemNPrice(reportData?.inspectiontemplates);

  const handleToggle = (e) => {
    if (e.target.checked) !selectedRatings?.includes(e.target.name) && setSelectedRatings((prev) => [...prev, e.target.name]);
    else selectedRatings?.includes(e.target.name) && setSelectedRatings((prev) => prev.filter((rating) => rating !== e.target.name));
  };

  // let ratingData =[];
  // reportData?.inspectiontemplates?.forEach(template=> (
  //  template.rating_data.forEach(rating=>ratingData.push(rating))
  //  ))
  // filtering all the availiable ratings from all the templates and removing the duplicates
  // let uniq = {};
  // let filteredRating = ratingData.filter(obj => !uniq[obj.Name.toLowerCase()] && (uniq[obj.Name.toLowerCase()] = true));

  // let defaultselected = filteredRating?.map(rating=>{
  //     if(rating.include_in_summary==='1') return rating.Name
  //   }).filter(item=> item != undefined)

  const getReportData = (type) => {
    type !== "noLoader" && setShowLoader(true);
    let data = { guid: sessionStorage.getItem("guid") };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setReportData(data);
          setShowLoader(false);
          let inspectionDetails = data.inspection_details;
          setSubject(`Request list for ${inspectionDetails?.location_address}, ${inspectionDetails?.location_city}, ${inspectionDetails?.location_state} ${inspectionDetails?.location_zip}`);
          let uniq = {};
          let ratingData = [];
          data?.inspectiontemplates?.forEach((template) => {
            if (template.is_pdf_template !== 1) {
              template.rating_data.forEach((rating) => ratingData.push(rating))
            }
          })
          let filteredRatingArray = ratingData.filter((obj) => !uniq[obj.Name.toLowerCase()] && (uniq[obj.Name.toLowerCase()] = true));
          setFilteredRating(filteredRatingArray);
          type !== "noLoader" &&
            setSelectedRatings(
              filteredRatingArray
                ?.map((rating) => {
                  if (rating.include_in_summary === "1" || rating?.include_in_summary === 1) return rating.Name;
                  else return undefined;
                })
                .filter((item) => item !== undefined)
            );
          let comtReqIds = getCommentReqListId(data?.inspectiontemplates)
          setSampleComentReqListId(comtReqIds)
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      data,
      "GET_REPORT_DATA"
    );
  };

  useEffect(() => {
    getReportData("normal");
  }, []);

  return (
    <>
      <Preloader showPreloader={showLoader} />
      <CreateRequestListModal isISN={isISN} createReqList={createReqList} clientData={reportData?.inspection_details?.orderclient} setCreateReqList={setCreateReqList} subject={subject} setSubject={setSubject} inspectionDetails={reportData?.inspection_details} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <SideMenu dataLoaded={dataLoaded} showLoader={showLoader} categoriesData={reportData.inspectiontemplates} isISN={isISN} selectedRatings={selectedRatings} />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <Grid container>
              <Grid item xs={12} md={9} className="repair__container">
                <ReportContent categoriesData={reportData?.inspectiontemplates} setReRender={setReRender} selectedRatings={selectedRatings} getReportData={getReportData} />
              </Grid>
              {/* filter section */}
              <Grid item xs={12} md={3} className="filter__container border-paper">
                <div className="close__icon" onClick={() => navigate("/")}>
                  <CloseIcon />
                </div>
                <Typography className="filter__title">Create a request list</Typography>
                <Typography className="filter__subtitle">Toggle to show report items. Select a report item to add it to the request list.</Typography>
                <Grid className="rl__toggler__container">
                  {filteredRating?.map((rating, ratingIndex) => (
                    <div className="rl__toggler" key={ratingIndex}>
                      <Switch defaultChecked={rating.include_in_summary === "1" || rating?.include_in_summary === 1} name={rating.Name} onChange={(e) => handleToggle(e)} className="switch" />
                      <div className="filter__box">
                        <span style={{ background: `${rating.Color}` }} className="filter_rating_color">
                          {rating.include_in_summary === "1" || rating?.include_in_summary === 1 ? <FlagIcon /> : ""}
                        </span>
                        {`${rating.Name} (${findRatingCount(reportData?.inspectiontemplates, rating.Name)})`}
                      </div>
                    </div>
                  ))}
                </Grid>
                {/* Total cost card             */}
                <RepairTotalCostCard inspData={reportData.inspectiontemplates} />
                <Grid className="rl__buttons">
                  <Button className={`border__button ${selectedCount < 1 ? "disabled_btn" : ""}`} sx={{ marginRight: "6px", width: "130px" }} disableFocusRipple onClick={selectedCount > 0 ? () => navigate("/preview") : undefined}>
                    <span className={`border__btn__text ${selectedCount < 1 ? "disabled_btn" : ""}`}>Preview</span>
                  </Button>
                  <Button className={`filled__button ${selectedCount < 1 ? "disabled_btn" : ""}`} sx={{ width: "125px" }} onClick={selectedCount > 0 ? () => setCreateReqList(true) : undefined}>
                    <span className={`filled__btn__text ${selectedCount < 1 ? "disabled_btn" : ""}`}>Create</span>
                  </Button>
                </Grid>
              </Grid>
              <Grid className="mobile_create_request_list">
                <FilterMobileSection filteredRating={filteredRating} reportData={reportData} setCreateReqList={setCreateReqList} selectedCount={selectedCount} handleToggle={handleToggle} />
              </Grid>
            </Grid>
          </motion.div>
        </Box>
      </Box>
      <ScrollToTop smooth className="scroll__to__top" component={<ArrowUpwardIcon />} />
    </>
  );
};
export default RequestList;
