import { useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import { convertFontTagsToInlineStyles } from "../pages/pdf/HtmlConverter";
import parse from "html-react-parser";
import config_settings from "../config/config";

export const ReadMore = (children) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const fontSizeConverter = (val) => {
    if (val === "1") {
      return 10;
    } else if (val === "2") {
      return 13;
    } else if (val === "3") {
      return 16;
    } else if (val === "4") {
      return 18;
    } else if (val === "5") {
      return 24;
    } else if (val === "6") {
      return 32;
    } else if (val === "7") {
      return 48;
    } else return val;
  };

  function convertFontTagsToInlineStyles(htmlString) {
    // Replace <font> tags with inline styles
    var convertedString = htmlString?.replace(/<font([^>]*)>/gi, function (match, p1) {
      var attributes = p1?.split(/\s+/);
      var styles = "";
      const fontSizeConverter = (val) => {
        if (val === "1") {
          return 10;
        } else if (val === "2") {
          return 13;
        } else if (val === "3") {
          return 16;
        } else if (val === "4") {
          return 18;
        } else if (val === "5") {
          return 24;
        } else if (val === "6") {
          return 32;
        } else if (val === "7") {
          return 48;
        } else return val;
      };
      // Convert font attributes to inline styles
      for (var i = 0; i < attributes?.length; i++) {
        var attribute = attributes[i]?.trim();
        var parts = attribute?.split("=");

        if (parts.length === 2) {
          var name = parts[0]?.toLowerCase();
          var value = parts[1]?.replace(/['"]/g, "");
          // eslint-disable-next-line
          switch (name) {
            case "color":
              styles += "color: " + value + "; ";
              break;
            case "size":
              styles += "font-size: " + fontSizeConverter(value) + "px;";
              break;
          }
        }
      }

      return "<span style='" + styles + "'>";
    });
    convertedString = convertedString?.replace(/<\/font>/gi, "</span>");

    return convertedString;
  }
  return (
    <div className="html_render">
      {children?.body?.length > 250 ? (
        isReadMore ? (
          <div>
            <div className="cat__description__read_more" dangerouslySetInnerHTML={{ __html: convertFontTagsToInlineStyles(children?.body?.slice(0, 250)) }}></div>
            <span onClick={toggleReadMore} className="cat__description__read_more_text" variant="none">
              <span>
                ...Read More <KeyboardArrowRightIcon />
              </span>
            </span>
          </div>
        ) : (
          <div>
            <div className="cat__description__read_less" dangerouslySetInnerHTML={{ __html: convertFontTagsToInlineStyles(children?.body) }}></div>
            <span onClick={toggleReadMore} className="cat__description__read_less_text" variant="none">
              <span>
                {" "}
                <KeyboardArrowLeftIcon /> Read less{" "}
              </span>{" "}
            </span>
          </div>
        )
      ) : children.body !== null ? (
        <div className="cat__description__read_less" dangerouslySetInnerHTML={{ __html: convertFontTagsToInlineStyles(children?.body) }}></div>
      ) : (
        ""
      )}
    </div>
  );
};

export function checkCategory(category) {
  let categoryStatus = [];
  category?.lines?.forEach((line) => {
    line.comments?.forEach((comment) => {
      comment?.comment_input_controls
        ?.find((commentCntrl) => commentCntrl?.type === "Rating")
        ?.data?.forEach((rating) => {
          if (rating?.include_in_summary === "1" || rating?.include_in_summary === 1) categoryStatus?.push("true");
          else categoryStatus?.push("false");
        });
    });
  });
  if (categoryStatus?.some((el) => el === "true")) return true;
  else return false;
}

export function checkSummaryLine(lineitem) {
  let summaryStatus = [];
  lineitem.comments.forEach((comment) => {
    comment?.comment_input_controls
      ?.find((e) => e?.type === "Rating")
      ?.data.forEach((rating) => {
        if (rating.include_in_summary === "1" || rating?.include_in_summary === 1) summaryStatus.push("true");
        else summaryStatus.push("false");
      });
  });
  if (summaryStatus.some((el) => el === "true")) return true;
  else return false;
}

export function checkSummaryComment(comnt) {
  let commentSummaryStatus = [];
  comnt?.comment_input_controls
    ?.find((e) => e?.type === "Rating")
    ?.data.forEach((rating) => {
      if (rating.include_in_summary === "1" || rating?.include_in_summary === 1) commentSummaryStatus.push("true");
      else commentSummaryStatus.push("false");
    });
  if (commentSummaryStatus.some((el) => el === "true")) return true;
  else return false;
}

export function checkFilters(category, filteredRatings) {
  let filterStatus = [];
  category?.lines?.forEach((line) => {
    line.comments?.forEach((comment) => {
      if (comment.is_selected !== 0) {
        let currentCommentRatingArray = comment?.comment_input_controls?.find((rating) => rating?.type === "Rating")?.data;
        currentCommentRatingArray?.forEach((rating) => {
          if (filteredRatings?.includes(rating.Name)) {
            filterStatus?.push("true");
          } else filterStatus?.push("false");
        });
      }
    });
  });
  if (filterStatus?.some((el) => el === "true")) return true;
  else return false;
}
export function checkFiltersLines(line, filteredRatings) {
  let filterStatusLines = [];
  line.comments?.forEach((comment) => {
    if (comment.is_selected !== 0) {
      let currentCommentRatingArray = comment?.comment_input_controls?.find((rating) => rating?.type === "Rating")?.data;
      currentCommentRatingArray?.forEach((rating) => {
        if (filteredRatings?.includes(rating.Name)) {
          filterStatusLines?.push("true");
        } else filterStatusLines?.push("false");
      });
    }
  });
  if (filterStatusLines?.some((el) => el === "true")) return true;
  else return false;
}
export function checkMultipleRating(ratingArray, selectedRatings) {
  let ratingStatus = [];
  ratingArray?.map((rating) => {
    if (selectedRatings?.includes(rating.Name)) ratingStatus.push("true");
    else ratingStatus.push("false");
  });
  return ratingStatus.some((el) => el === "true");
}
export function checkRepairSelectionTemplate(templates) {
  let templateRepairSelectionStatus = [];
  templates?.forEach((template) => {
    template?.categories?.map((category) => {
      category?.lines?.forEach((line) => {
        line.comments?.forEach((comment) => {
          if (comment?.comment_request_list[0]?.is_selected === 1) templateRepairSelectionStatus.push("true");
          else templateRepairSelectionStatus?.push("false");
        });
      });
    });
  });
  if (templateRepairSelectionStatus?.some((el) => el === "true")) return true;
  else return false;
}

export function checkRepairSelectionCategory(categories) {
  let categoryRepairSelectionStatus = [];
  categories.map((category) => {
    category?.lines?.forEach((line) => {
      line.comments?.forEach((comment) => {
        if (comment?.comment_request_list[0]?.is_selected === 1) categoryRepairSelectionStatus.push("true");
        else categoryRepairSelectionStatus.push("false");
      });
    });
  });
  if (categoryRepairSelectionStatus?.some((el) => el === "true")) return true;
  else return false;
}

export function checkRepairSelectionLine(lines) {
  let LineRepairSelectionStatus = [];
  lines?.forEach((line) => {
    line?.comments?.forEach((comment) => {
      if (comment?.comment_request_list[0]?.is_selected === 1) LineRepairSelectionStatus.push("true");
      else LineRepairSelectionStatus?.push("false");
    });
  });
  if (LineRepairSelectionStatus?.some((el) => el === "true")) return true;
  else return false;
}

export function checkRepairSelectionComment(comments) {
  let commentRepairSelectionStatus = [];
  comments?.forEach((comment) => {
    if (comment.is_selected !== 0) {
      if (comment?.comment_request_list[0]?.is_selected === 1) commentRepairSelectionStatus.push("true");
      else commentRepairSelectionStatus.push("false");
    }
  });
  if (commentRepairSelectionStatus.some((el) => el === "true")) return true;
  else return false;
}

export function checkTotalItemNPrice(templates) {
  let selectedCount = 0;
  let totalCost = 0;
  templates?.forEach((template) => {
    template?.categories?.map((category) => {
      category?.lines?.forEach((line) => {
        line.comments?.forEach((comment) => {
          if (comment.is_selected !== 0) {
            if (comment?.comment_request_list[0]?.is_selected === 1) {
              selectedCount += 1;
              if (comment?.comment_request_list[0]?.request_list_data.length !== 0 && comment?.comment_request_list[0]?.request_list_data[0].comment_request_list_pricing !== null) {
                totalCost += Number(comment?.comment_request_list[0]?.request_list_data[0].comment_request_list_pricing);
              }
            }
          }
        });
      });
    });
  });
  return { selectedCount, totalCost };
}

export function findRatingCount(reportData, ratingName) {
  let ratingCount = 0;
  reportData?.forEach((template) => {
    template?.categories?.map((category) => {
      category?.lines?.forEach((line) => {
        line.comments?.forEach((comment) => {
          if (comment.is_selected !== 0) {
            comment?.comment_input_controls
              ?.find((commentCntrl) => commentCntrl.type === "Rating")
              ?.data?.forEach((rating) => {
                if (rating.Name === ratingName) ratingCount += 1;
              });
          }
        });
      });
    });
  });
  return ratingCount;
}
// check for comment present in each line
export const checkRepairLineComment = (comments) => {
  let commentsPresentData = [];
  comments?.forEach((comment) => {
    if (comment.is_selected !== 0) {
      if (comment?.comment_request_list[0]?.is_selected === 1) {
        if (comment?.comment_request_list[0]?.request_list_data[0]?.comment_request_list_info !== null) commentsPresentData.push("true");
        else commentsPresentData?.push("false");
      }
    }
  });
  if (commentsPresentData.some((el) => el === "true")) return true;
  else return false;
};

// get the cost and comment details in repair list page
export const lineRepairDetails = (templates) => {
  let lineData = [];
  templates?.forEach((template) => {
    let count = 1;
    template?.categories
      ?.filter((e) => e.is_inspection_details !== 1 && e.is_inspection_details !== 2)
      ?.map((category, catIndex) => {
        if (category.is_deleted !== 1) {
          category?.lines?.filter((e) => e?.is_deleted === 0)?.forEach((line, lineIndex) => {
            if (checkRepairSelectionComment(line.comments)) {
              let totCost = 0;
              line.comments?.forEach((comment) => {
                if (comment.is_selected !== 0) {
                  if (comment?.comment_request_list !== undefined && comment?.comment_request_list.length !== 0) {
                    if (comment?.comment_request_list[0].request_list_data.length !== 0 && comment?.comment_request_list[0].request_list_data.length !== undefined) {
                      if (comment?.comment_request_list[0]?.is_selected === 1) {
                        if (comment?.comment_request_list[0]?.request_list_data[0].comment_request_list_pricing !== null) {
                          totCost += Number(comment?.comment_request_list[0]?.request_list_data[0].comment_request_list_pricing);
                        }
                      }
                    }
                  }
                }
              });
              if (checkRepairLineComment(line.comments)) lineData.push({ lineName: `${count}.${lineIndex + 1} ${line.prompt}`, cost: totCost, commentPresent: true });
              else lineData.push({ lineName: `${count}.${lineIndex + 1} ${line.prompt}`, cost: totCost, commentPresent: false });
            }
          });
          count++;
        }
      });
  });
  return { lineData };
};

export const onKeyPressed = (evt) => {
  if (evt.key === "e" || evt.key === "E") {
    evt.preventDefault();
  }
};
export function formatCost(value) {
  let result;
  let parts = value.toString().split(".");
  let whole_num = parts[0].replace(/\D/g, ""),
    dec = parts[1];
  // use the below commented number for numeric calculations(num without commas)
  let calc_num = Number(dec !== undefined ? whole_num + "." + dec : whole_num);
  // result = new Intl.NumberFormat('en-EN').format(whole_num);
  // result = dec !== undefined ? result + '.' + dec : result;
  // return { formatted: result, calculation: calc_num };
  return calc_num;
}

export const origin = () => {
  let BASE_URL;
  let origin = window.location.origin.toLowerCase();
  if (origin?.includes("https://")) origin = origin.replace("https://", "");
  else origin = origin.replace("http://", "");
  BASE_URL = config_settings[origin].SERVER_URL;
  return BASE_URL;
};

export const originBase = () => {
  let ORIGIN_URL = window.location.origin.toLowerCase();
  return ORIGIN_URL;
};

export const getCommentReqListId = (templates) => {
  let reqIds = []
  templates.map(template => (
    template?.categories?.map(category => (
      category?.lines?.filter((e) => e?.is_deleted === 0)?.map(line => (
        line?.comments?.map(comment => {
          if (comment?.comment_request_list !== null && comment?.comment_request_list.length !== 0) {
            reqIds.push(comment?.comment_request_list[0]?.id)
          }
        })
      ))
    ))
  ))
  return reqIds
}
