import { motion } from "framer-motion";
import SideMenu from "../../components/Menu/SideMenu";
import ReportContent from "../fullReport/ReportContent";
import { Box, CssBaseline, Grid, Toolbar } from "@mui/material";
import NarrativeSummary from "../fullReport/narrativeFullReport/NarrativeSummary";

const Summary = ({ dataLoaded, reportData, componentRef, isISN, showLoader, isNarrative }) => {
 
  return (
    <>
      {window.scrollTo(0, 0)}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <SideMenu dataLoaded={dataLoaded} isNarrative={isNarrative} categoriesData={reportData.inspectiontemplates} isISN={isISN} showLoader={showLoader} />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <Toolbar />
          <Toolbar />
          <motion.div initial={{ opacity: 0.5 }} animate={{ opacity: 1 }} style={{ height: "100vh" }}>
            <Grid item xs={12} className="container" ref={componentRef}>
              {isNarrative ? <NarrativeSummary summaryData={reportData?.summaryDataNarrativeTemplate} /> : <ReportContent categoriesData={reportData?.inspectiontemplates} />}
            </Grid>
          </motion.div>
        </Box>
      </Box>
    </>
  );
};

export default Summary;
